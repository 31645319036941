/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum DeliveryType {
  BURN_IN_ADVANCED = "BURN_IN_ADVANCED",
  BURN_IN_BASIC = "BURN_IN_BASIC",
  NO_BURN_IN = "NO_BURN_IN",
  NO_TYPESETTER = "NO_TYPESETTER",
  TYPESETTING_PAGE_TO_PAGE = "TYPESETTING_PAGE_TO_PAGE",
  TYPESETTING_PAGE_TO_SCROLL = "TYPESETTING_PAGE_TO_SCROLL",
  TYPESETTING_SCROLL_TO_PAGE = "TYPESETTING_SCROLL_TO_PAGE",
  TYPESETTING_SCROLL_TO_SCROLL = "TYPESETTING_SCROLL_TO_SCROLL",
}

export enum DocumentStatus {
  CANCELLED = "CANCELLED",
  PROCESSED = "PROCESSED",
  REGISTERED = "REGISTERED",
}

export enum FileStatus {
  ERROR = "ERROR",
  REGISTERED = "REGISTERED",
  REGISTERING = "REGISTERING",
  UPLOADING = "UPLOADING",
}

export enum JobResultStatus {
  DONE = "DONE",
  DROP = "DROP",
  PROCESSING = "PROCESSING",
  REGISTERED = "REGISTERED",
  REQUESTED = "REQUESTED",
  REVIEWABLE = "REVIEWABLE",
  REVIEW_APPROVED = "REVIEW_APPROVED",
  REVIEW_REJECTED = "REVIEW_REJECTED",
}

export enum JobStatus {
  DONE = "DONE",
  DROP = "DROP",
  PROCESSING = "PROCESSING",
  REGISTERED = "REGISTERED",
  REQUESTED = "REQUESTED",
  REQUESTING = "REQUESTING",
  REVIEWABLE = "REVIEWABLE",
}

export enum ProductOptionType {
  DISCOUNT_ON_BYPRODUCT = "DISCOUNT_ON_BYPRODUCT",
  DISCOUNT_ON_DEFAULT_CAPTION = "DISCOUNT_ON_DEFAULT_CAPTION",
  DISCOUNT_ON_DETACHED_LAYER = "DISCOUNT_ON_DETACHED_LAYER",
  REQUEST_EDITING = "REQUEST_EDITING",
  URGENT_TASK = "URGENT_TASK",
}

export enum ProductSource {
  BASIC = "BASIC",
  SCREEN_ONLY = "SCREEN_ONLY",
  VOICE_AND_SCREEN = "VOICE_AND_SCREEN",
  VOICE_ONLY = "VOICE_ONLY",
}

export enum ProductType {
  BURN_IN = "BURN_IN",
  CAPTION = "CAPTION",
  SPOTTING_ONLY = "SPOTTING_ONLY",
  TIME_CODE = "TIME_CODE",
  TRANSLATION = "TRANSLATION",
  TYPESETTER_ONLY = "TYPESETTER_ONLY",
}

export enum ProjectStatus {
  BALANCE_DONE = "BALANCE_DONE",
  BALANCE_REQUESTED = "BALANCE_REQUESTED",
  DONE = "DONE",
  DROP = "DROP",
  PROCESSING = "PROCESSING",
  REGISTERED = "REGISTERED",
  REQUESTED = "REQUESTED",
  REQUESTING = "REQUESTING",
}

export enum RequestCategory {
  DOCUMENT = "DOCUMENT",
  VIDEO = "VIDEO",
  WEBNOVEL = "WEBNOVEL",
  WEBTOON = "WEBTOON",
}

export enum RequestFileType {
  ATTACHED_FILE = "ATTACHED_FILE",
  CONTENTS_FILE = "CONTENTS_FILE",
}

export enum VideoProcessRequestStatus {
  DONE = "DONE",
  ERROR = "ERROR",
  REGISTERED = "REGISTERED",
}

export enum WorkSizeType {
  EPISODE = "EPISODE",
  MINUTE = "MINUTE",
  WORD = "WORD",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
